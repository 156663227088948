<template>
  
  <section id="contact" class="contact_content">

    <div class="contact_content--panel">
      <form class="contact_content--form" action="" method="">

        <div class="contact_content--form_item">
          <label class="contact_content--label" for="name">
            Nome
            <span
              class="contact_content--error"
              v-if="name_err.status"
            >
              {{ name_err.msg }}
            </span>
          </label>
          <input
            class="contact_content--input"
            :class="{'contact_content--error_input': name_err.status}"
            id="name"
            type="text"
            v-model="name"
          >
        </div>

        <div class="contact_content--form_item">
          <label class="contact_content--label" for="email">
            E-mail
            <span
              class="contact_content--error"
              v-if="email_err.status"
            >
              {{ email_err.msg }}
            </span>
          </label>
          <input
            class="contact_content--input"
            :class="{'contact_content--error_input': email_err.status}"
            id="email"
            type="email"
            v-model="email"
          >
        </div>

        <div class="contact_content--form_item">
          <label class="contact_content--label" for="phone">
            DDD + Telefone (insira apenas números)
            <span
              class="contact_content--error"
              v-if="phone_err.status"
            >
              {{ phone_err.msg }}
            </span>
          </label>
          <input
            class="contact_content--input"
            :class="{'contact_content--error_input': phone_err.status}"
            id="phone"
            type="text"
            placeholder="(  ) _ ____-____"
            v-model="phone"
          >
        </div>

        <div class="contact_content--form_item">
          <label class="contact_content--label" for="message">
            Mensagem
            <span
              class="contact_content--error"
              v-if="message_err.status"
            >
              {{ message_err.msg }}
            </span>
          </label>
          <textarea
            class="contact_content--textarea"
            :class="{'contact_content--error_input': message_err.status}"
            id="message"
            rows="2"
            v-model="message"
          ></textarea>
        </div>

        <div class="contact_content--form_item">

          <div
            class="contact_content--form_notif"
          >
            <span
              :class="{
                'contact_content--success': contact_notif.status,
                'contact_content--error': !contact_notif.status
              }"
            >
              {{ contact_notif.msg }}
            </span>
          </div>

          <button
            class="contact_content--button"
            type="button"
            @click="validForm()"
          >
            <span
              v-if="!show_loading_response"
            >
              Enviar
            </span>
            
            <Loading v-else/>
          </button>
        </div>

      </form>
    </div>

    <div
      class="contact_content--panel"
      :style="{
        backgroundImage: `url(${contactData.background})`
      }"
    >
      <section>

        <div class="contact_content--panel_item contact_content--media">
          <div
            v-for="(item, id) in socialMediaItems"
            :key="id"
          >
            <a
              :href="item.link"
              target="_blank"
            >
              <div
                class="contact_content--icon contact_content--media_item"
                :style="{
                  backgroundImage: `url(./${item.image})`
                }"
              ></div>
            </a>
          </div>
        </div>

        <div class="contact_content--panel_item">
          <div
            v-for="(item, id) in addressItems"
            :key="id"
          >
            <div
              class="contact_content--icon"
              v-if="item.image.length"
              :style="{
                backgroundImage: `url(./${item.image})`
              }"
            ></div>
            <span v-else>
              {{ item.text }}
            </span>
          </div>
        </div>

        <div class="contact_content--panel_item">
          <div
            v-for="(item, id) in contactItems"
            :key="id"
          >
            <div
              class="contact_content--icon"
              v-if="item.image.length"
              :style="{
                backgroundImage: `url(./${item.image})`
              }"
            ></div>
            <span v-else>
              {{ item.text }}
            </span>
          </div>
        </div>

      </section>
    </div>

  </section>

</template>

<script>
import axios from 'axios';
import Loading from '@/components/Loading';

export default {
  name: 'Contact',
  props: {
    contactData: Object
  },
  components: {
    Loading
  },
  data () {
    return {
      show_loading_response: false,
      addressItems: [],
      contactItems: [],
      socialMediaItems: [],
      name: ``,
      name_err: {
        status: false,
        msg: `- Nome inválido!`
      },
      email: ``,
      email_err: {
        status: false,
        msg: `- E-mail inválido!`
      },
      phone: ``,
      phone_err: {
        status: false,
        msg: `- Número inválido!`
      },
      message: ``,
      message_err: {
        status: false,
        msg: `- Mensagem inválida!`
      },
      contact_notif: {
        status: false,
        msg: ``
      }
    }
  },
  created () {
    this.sctructureContactElements();
  },
  watch: {
    phone (number) {
      if (!(/(^\(([0-9]{2}|[0-9]{3})\)\s[0-9]{1}\s[0-9]{4}-[0-9]{4}$)|(^[0-9]+$)/.test(number))) {
        this.phone_err.status = true;

        return this.phone = ``;
      }
      else {
        this.phone_err.status = false;
      }
      
      return;
    }
  },
  methods: {
    sctructureContactElements () {
      this.addressItems = this.contactData.data.address.sort(
        (a, b) => {
          return a.id - b.id;
        }
      );

      this.contactItems = this.contactData.data.contact.sort(
        (a, b) => {
          return a.id - b.id;
        }
      );

      this.socialMediaItems = this.contactData.data.social_media.sort(
        (a, b) => {
          return a.id - b.id;
        }
      );

      return;
    },
    validForm () {
      this.resetLayoutErr();
      this.validName();
      this.validEmail();
      this.phone = this.maskPhone();
      this.validMessage();
      this.sendContact();
    },
    resetLayoutErr () {
      this.name_err.status = false;
      this.email_err.status = false;
      this.phone_err.status = false;
      this.message_err.status = false;
      
      return;
    },
    validName () {
      try {
        if (
          this.name.length === 0
          || this.name.length > 100
        ) {
          throw true;
        }

        return;
      } catch (error) {
        this.name_err.status = true;
        return;
      }
    },
    validEmail () {
      try {
        let trim_email = this.email.replace(/\s/g, '');

        if (this.email.length === 0) {
          throw true;
        }

        let match_mail = trim_email.match(/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g);

        if (match_mail === null) {
          throw true;
        }

        return;
      } catch (error) {
        this.email_err.status = true;
        return;
      }
    },
    validPhone () {
      try {
        let trim_phone = this.phone.replace(/[^\d]/g, '');
        
        if (trim_phone.length === 11) {
          trim_phone = `0${trim_phone}`;
        }
  
        if (trim_phone.length > 12 || trim_phone.length < 11) {
          throw true;
        }
  
        let match_phone = trim_phone.match(/\d{12}/g);
  
        if (match_phone == null) {
          throw true;
        }
  
        return match_phone[0].toString();
      } catch (error) {
        this.phone_err.status = true;
        return this.phone;
      }
    },
    maskPhone () {
      try {
        let valid_phone = this.validPhone();

        if (valid_phone.length !== 12) {
          throw true;
        }

        const DDD = valid_phone.substring(0, 3);
        const NINTH_DIGIT = valid_phone.substring(3, 4);
        const FIRST_PART = valid_phone.substring(4, 8);
        const SECOND_PART = valid_phone.substring(8, 12);

        return `(${DDD}) ${NINTH_DIGIT} ${FIRST_PART}-${SECOND_PART}`;
      } catch (error) {
        this.phone_err.status = true;
        return this.phone;
      }
      
    },
    validMessage () {
      try {
        if (
          this.message.length === 0
          || this.message.length > 500
        ) {
          throw true;
        }

        return;
      } catch (error) {
        this.message_err.status = true;
        return;
      }
    },
    async sendContact () {
      try {
        this.contact_notif.msg = ``;

        if (
          this.name_err.status
          || this.email_err.status
          || this.phone_err.status
          || this.message_err.status
        ) {
          return;
        }

        this.show_loading_response = true;

        let bodyFormData = new FormData();
        bodyFormData.set(`name`, this.name);
        bodyFormData.set(`email`, this.email);
        bodyFormData.set(`phone`, this.phone);
        bodyFormData.set(`message`, this.message);

        let config = {
          // headers: {
          //   Authorization: {
          //     toString () {
          //       return `Bearer ${localStorage.getItem('access_token')}`
          //     }
          //   }
          // }
          // headers: {
          //   'Access-Control-Allow-Origin' : 'https://www.somoscope.com.br',
          //   'Access-Control-Allow-Methods': 'POST',
          // }
        };

        await axios
          .post(`https://somoscope.com.br/api/notification`, bodyFormData, config)
          .then(response => {
            this.show_loading_response = false;
            
            if (!response.data.status) {
              throw false;
            }

            this.contact_notif.status = true;
            this.contact_notif.msg = `Mensagem enviada.`;

            return;
          });

        return;
      } catch (error) {
        this.show_loading_response = false;
        this.contact_notif.status = false;
        this.contact_notif.msg = `Problema ao enviar sua mensagem.`;

        return;
      }
    }
  }
}
</script>

<style lang="scss">
@import './../assets/variables';

.contact_content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh - $height-header-normal;

  &--panel {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 5%;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  &--form,
  &--media {
    width: 100%;
  }

  &--form_item {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    margin: 1rem 0;
  }

  &--label,
  &--input,
  &--textarea,
  &--button {
    font-size: 18px;
  }

  &--label {
    width: 100%;
  }

  &--input,
  &--textarea {
    width: 100%;
    padding: .5rem;
    border: 1px solid $theme-color-dark;
  }

  &--button {
    padding: .6rem 0;
    width: 10rem;
    border: none;
    color: $font-color-light;
    background-color: $theme-color-blue;
    border: 1px solid $theme-color-blue;
    cursor: pointer;
    transition:
      color .5s $animation-cubic-bezier,
      background-color .5s $animation-cubic-bezier,
      border .5s $animation-cubic-bezier;

    &:hover {
      color: $font-color-dark;
      background-color: $theme-color-blue-light;
      border: 1px solid $theme-color-dark;
    }
  }

  &--panel_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem 0;
    font-size: 18px;
  }

  &--icon {
    width: 32px;
    height: 32px;
    margin: 1rem 0;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  &--media {
    flex-direction: row;
    justify-content: space-between;
  }

  &--media_item {
    width: 48px;
    height: 48px;
    margin: 0 .5rem;
  }

  &--form_notif {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
  }

  &--success {
    color: $font-color-blue;
  }

  &--error,
  &--error_input {
    color: $font-color-error;
  }

  &--error_input {
    border: 1px solid $font-color-error;
  }
}

@media (max-width: 900px) {
  .contact_content {
    flex-direction: column-reverse;
    height: inherit;
    padding: 4rem 0;

    &--panel {
      width: 100%;
    }
  }
}
</style>
