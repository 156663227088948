<template>
  
  <section
    id="copedigital"
    class="copedigital_content"
    :style="{
      backgroundImage: `url(${copeDigitalData.background})`,
      backgroundAttachment: `${backgroundAttachment}`
    }"
    >

    <h2 class="copedigital_content--title">
      {{ copeDigitalData.title }}
    </h2>

    <article
      class="copedigital_content--panel"
      v-for="(item, id) in copeDigitalItems"
      :key="id"
    >
      <div
        class="copedigital_content--figure"
        :style="{
          backgroundImage: `url(${item.image})`
        }"
      >
      </div>
           
      <p
        class="copedigital_content--text"
        :style="item.style"
      >
        {{ item.text }}
      </p>
    </article>

    <div class="copedigital_content--panel">
      <h3>{{ vemSerCopeItems[0].text }}</h3>

      <div
        class="copedigital_content--component"
        @click="showNewsletter = true"
      >
        {{ vemSerCopeItems[1].text }}
      </div>
    </div>

    <newsletter
      v-if="showNewsletter"
      @close="showNewsletter = false"
      :newsletterData="newsletterData"
    />
  </section>

</template>

<script>
import Newsletter from '@/components/Newsletter';

export default {
  name: 'CopeDigital',
  components: {
    Newsletter
  },
    props: {
      copeDigitalData: Object,
      vemSerCopeData: Array,
      newsletterData: Object
    },
    data () {
      return {
        copeDigitalItems: [],
        vemSerCopeItems: [],
        showNewsletter: false,
        backgroundAttachment: 'initial'
      }
    },
    created () {
      this.sctructureCopeDigitalElements();
      this.isMobile();
    },
    methods: {
      isMobile () {
        return this.backgroundAttachment = window.orientation !== undefined ? 'initial' : 'fixed';
      },
      sctructureCopeDigitalElements () {
        this.copeDigitalItems = this.copeDigitalData.data.sort(
          (a, b) =>
            {
              return a.id - b.id;
            }
        );
            
        this.vemSerCopeItems = this.vemSerCopeData.sort(
          (a, b) =>
            {
              return a.id - b.id;
            }
        );

        return;
      }
    }
}
</script>

<style lang="scss">
@import './../assets/variables';

.copedigital_content {
  width: 100%;
  padding-top: $height-header-normal;
  padding-bottom: 5rem;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
  background-attachment: fixed;
  color: $font-color-light;

  &--title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 35vh;
    font-size: 42px;
    text-transform: uppercase;
    letter-spacing: 5px;
    background: $theme-color-blue;
  }

  &--panel {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 5rem 0;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }

    h3 {
      font-size: 28px;
      letter-spacing: 5px;
    }
  }

  &--figure {
    width: 450px;
    height: 400px;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  &--text {
    width: 50%;
    padding: .3rem 3rem;
    font-size: 24px;
    text-align: justify;
  }

  &--component {
    font-size: 28px;
    padding: 1.5rem 4rem;
    border: 1px solid $theme-color-light;
    cursor: pointer;
    color: $font-color-light;
    background-color: transparent;
    transition:
      background-color 1s $animation-cubic-bezier,
      color 1s $animation-cubic-bezier;

    &:hover {
      color: $font-color-dark;
      background-color: $theme-color-light;
    }
  }
}

@media (max-width: 900px) {
  .copedigital_content {

    &--title {
      font-size: 24px;
      letter-spacing: 2px;
    }

    &--panel {
      flex-direction: column;
      padding: 0;
      padding-top: 3rem;

      &:nth-child(even) {
        flex-direction: column;
      }

      h3 {
        font-size: 24px;
        letter-spacing: 2px;
      }
    }

    &--figure {
      width: 100%;
      height: 250px;
    }

    &--text {
      width: 100%;
      padding: 2rem;
      font-size: 20px;
    }

    &--component {
      font-size: 22px;
      padding: 1rem 2rem;
      margin: 4rem 0;
    }
  }
}
</style>
