<template>
  
  <section
    id="team"
    class="team_content"
    :style="{
      backgroundImage: `url(${teamData.background})`,
      backgroundAttachment: `${backgroundAttachment}`
    }"
  >
    <div
      class="team_content--member"
      v-for="(member, index) in teamData.data"
      :key="index"
    >
      
      <h2 class="team_content--member--name">
        {{ member.text }}
      </h2>

      <img
        class="team_content--member--image"
        :src="member.image"
        :alt="member.text"
      >

      <div class="team_content--member--position">
        <span>
          {{ member.position }}
        </span>
      </div>

      <div class="team_content--member--crc"
        v-if="member.crc"
      >
        <span>
          {{ member.crc }}
        </span>
      </div>

      <!-- <div class="team_content--member--about">
        <span>
          {{ member.about }}
        </span>
      </div> -->

    </div>
  </section>

</template>

<script>
export default {
  name: 'About',
  props: {
    teamData: Object
  },
  data () {
    return {
      backgroundAttachment: 'initial'
  //     aboutItems: []
    }
  },
  created () {
    this.isMobile();
  //   this.sctructureAboutElements();
  },
  methods: {
    isMobile () {
      return this.backgroundAttachment = window.orientation !== undefined ? 'initial' : 'fixed';
    }
  //   sctructureAboutElements () {
  //     return this.aboutItems = this.teamData.data.sort(
  //       (a, b) => {
  //         return a.id - b.id;
  //       }
  //     );
  //   }
  }
}
</script>

<style lang="scss">
@import './../assets/variables';

.team_content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  padding-top: $height-header-normal;
  padding-bottom: 5rem;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
  background-attachment: fixed;
  color: $theme-color-dark;
  
  &--member {
    width: 500px;
    padding: 1rem;
    margin: 2rem 1rem;
    text-align: center;

    &--name {
      font-size: 36px;
    }

    &--image {
      width: 250px;
      height: 250px;
    }

    &--position {
      font-size: 24px;
    }

    &--crc,
    &--about {
      font-size: 18px;
    }

    &--about {
      padding: 1rem 0;
      text-indent: 2rem;
      text-align: justify;
    }
  }
}

@media (max-width: 900px) {
  .team_content {

    &--item {
      width: 80%;
    }
  }
}
</style>
