<template>
  <transition name="modal">
    <div class="newsletter_component">
      <div class="newsletter_component--wrapper">
        <section class="newsletter_component--container">
          <div class="newsletter_component--item">
            <div class="newsletter_component--wrap_item">
              <h3>{{ newsletterData.title }}</h3>
              <div class="newsletter_component--form">
                <input
                  class="newsletter_component--input"
                  :class="{'newsletter_component--error_input': email_err.status}"
                  type="text"
                  placeholder="Seu e-mail"
                  v-model="email"
                >
                <div class="newsletter_component--button">
                  <button
                    type="button"
                    @click="sendNewsletter()"
                    v-if="!show_loading_response"
                  >
                    Assinar
                  </button>
                  <Loading v-else/>
                </div>
                <div class="newsletter_component--notif_item">
                  <span
                    class="newsletter_component--error"
                    v-if="email_err.status"
                  >
                    {{ email_err.msg }}
                  </span>
                  <span
                    :class="{
                      'newsletter_component--success': newsletter_notif.status,
                      'newsletter_component--error': !newsletter_notif.status
                    }"
                    v-else
                  >
                    {{ newsletter_notif.msg }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="newsletter_component--item"
            :style="{ backgroundImage: `url(${newsletterData.background}` }"
          >
            <div
              class="newsletter_component--close"
              @click="$emit('close')"
            >
            </div>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/Loading';

export default {
  name: 'Newsletter',
  components: {
    Loading
  },
  props: {
    newsletterData: Object
  },
  data () {
    return {
      show_loading_response: false,
      email: ``,
      email_err: {
        status: false,
        msg: `E-mail inválido!`
      },
      newsletter_notif: {
        status: false,
        msg: ``
      }
    }
  },
  methods: {
    validEmail () {
      try {
        let trim_email = this.email.replace(/\s/g, '');

        if (this.email.length === 0) {
          throw true;
        }

        let match_mail = trim_email.match(/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g);

        if (match_mail === null) {
          throw true;
        }

        this.email_err.status = false;

        return true;
      } catch (error) {
        this.email_err.status = true;
        return false;
      }
    },
    async sendNewsletter () {
      try {
        this.newsletter_notif.msg = ``;

        if (!this.validEmail()) {
          return;
        }

        this.show_loading_response = true;

        let bodyFormData = new FormData();
        bodyFormData.set(`email`, this.email);

        let config = {
          // headers: {
          //   Authorization: {
          //     toString () {
          //       return `Bearer ${localStorage.getItem('access_token')}`
          //     }
          //   }
          // }
          // headers: {
          //   'Access-Control-Allow-Origin' : 'https://www.somoscope.com.br',
          //   'Access-Control-Allow-Methods': 'POST',
          // }
        };

        await axios
          .post(`https://somoscope.com.br/api/newsletter`, bodyFormData, config)
          .then(response => {
            this.show_loading_response = false;
            
            if (!response.data.status) {
              throw false;
            }

            this.newsletter_notif.status = true;
            this.newsletter_notif.msg = `E-mail cadastrado com sucesso.`;

            return;
          });

        return;
      } catch (error) {
        this.show_loading_response = false;
        this.newsletter_notif.status = false;
        this.newsletter_notif.msg = `Tivemos um problema ao registrar seu e-mail.`;

        return;
      }
    }
  }
}
</script>

<style lang="scss">
@import './../assets/variables';

.newsletter_component {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity .3s ease;

  &--wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  &--container {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    height: 90%;
    margin: 0px auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    color: $font-color-dark;
    background-color: $theme-color-light;

    transition: all .3s ease;
  }

  &--item {
    flex: 1;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  &--wrap_item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    padding: 4rem;

    & h3 {
      font-size: 48px;
    }
  }

  &--form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &--input {
    width: 70%;
    font-size: 20px;
    padding: .5rem 1rem;
  }

  &--button {
    width: 30%;
    border: 2px solid $theme-color-blue;
    background-color: $theme-color-blue;

    button {
      width: 100%;
      height: 100%;
      padding: .5rem 1rem;
      border: none;
      cursor: pointer;
      font-size: 20px;
      color: $font-color-light;
      background-color: $theme-color-blue;
    }
  }

  &--close {
    width: 100%;
    text-align: right;
    color: $font-color-light;

    &:hover {
      color: $font-color-error;
    }

    &::after {
      content: '×';
      font-size: 68px;
      padding: 0 1rem;
      cursor: pointer;
      background: #00000055;
    }
  }

  &--success {
    color: $font-color-blue;
  }

  &--error,
  &--error_input {
    color: $font-color-error;
  }

  &--notif_item {
    width: 100%;
    height: 24px;
    padding: .5rem 0;
  }

  &--error_input {
    border: 1px solid $font-color-error;
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .newsletter_component--container,
.modal-leave-active .newsletter_component--container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media (max-width: 720px) {
  .newsletter_component {
    
    &--wrap_item {
      padding: 2rem;

      & h3 {
        font-size: 32px;
      }
    }

    &--input {
      font-size: 16px;
    }

    &--button button {
      padding: 0;
      font-size: 16px;
    }
  }
}

@media (max-width: 500px) {
  .newsletter_component {

    &--container {
      flex-direction: column-reverse;
    }

    &--item {
      
      &:first-child {
        flex: 4;
      }

      &:last-child {
        flex: 1;
      }
    }

    &--wrap_item {
      padding: 1rem;

      & h3 {
        font-size: 28px;
      }
    }
  }
}
</style>