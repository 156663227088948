<template>
  
  <header class="header_app">

    <a :href="href">
      <img class="header_app--img" src="./../assets/images/logo.png" alt="Cope Logo">
    </a>

    <nav
      class="header_app--nav"
      :class="{
          'header_app--nav_close': !openNav,
          'header_app--nav_open': openNav
        }"
    >
      <ul>
        <li @click="toggleQuote()">
          <a class="header_app--navlink header_app--target" href="https://planos.somoscope.com.br/" target="_blank">
            Planos
          </a>
        </li>

        <li @click="toggleQuote()">
          <a class="header_app--navlink" href="#about">
            Quem Somos
          </a>
        </li>

        <li @click="toggleQuote()">
          <a class="header_app--navlink" href="#team">
            Equipe
          </a>
        </li>

        <!-- <li @click="toggleQuote()">
          <a class="header_app--navlink" href="#services">
            Serviços
          </a>
        </li> -->

        <li @click="toggleQuote()">
          <a class="header_app--navlink" href="#copedigital">
            Cope Digital
          </a>
        </li>

        <li @click="toggleQuote()">
          <a class="header_app--navlink" href="#contact">
            Contato
          </a>
        </li>
      </ul>
    </nav>

    <div
      class="header_app--quote"
      :class="{
          'header_app--close_quote': !openNav,
          'header_app--open_quote': openNav
        }"
      @click="toggleQuote()"
    ></div>

  </header>

</template>

<script>
export default {
  name: 'HeaderApp',
  data () {
    return {
      openNav: false,
      href: ``
    }
  },
  created () {
    return this.href = window.location.origin;
  },
  methods: {
    toggleQuote () {
      this.openNav = !this.openNav;
    }
  }
}
</script>

<style lang="scss">
@import './../assets/variables';

.header_app,
.header_app--nav ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Champagne & Limousines";
}

.header_app {
  position: fixed;
  top: 0;
  width: 100%;
  height: $height-header-normal;
  font-size: 20px;
  z-index: 98;
  background-color: $theme-color-dark;

  &--img {
    height: 75px;
    padding-left: .5rem;
  }

  &--nav {
    padding: 0 2rem;

    & li {
      margin-left: 1rem;

      &:first-child {
        margin: 0;
      }
    }
  }

  &--navlink {
    position: relative;
    padding: 1rem;
    color: $font-color-light;
    transition: background-color .5s $animation-cubic-bezier;

    &::after {
      content: "•";
      position: absolute;
      bottom: 0;
      margin: 0;
      font-size: 36px;
      color: transparent;
      transition: color .2s $animation-cubic-bezier;
    }

    &:hover {
      &::after {
        color: $theme-color-blue;
      }
    }
  }

  &--target {
    font-weight: bold;
  }

  &--quote {
    display: none;
  }
}

@media (max-width: 900px) {
  .header_app {
    
    &--nav {
      position: fixed;
      top: 0;
      right: 0;
      width: 60vw;
      height: 100vh;
      display: flex;
      align-items: center;
      padding: 0;
      background: $font-color-dark;
      box-shadow: 1px 1px 10px $theme-color-blue;
      transition: transform .5s $animation-cubic-bezier;

      ul {
        width: 100%;
        flex-direction: column;
        align-items: flex-end;
      }

      li {
        width: 100%;
        padding: 0;
      }
    }

    &--nav_open {
      transform: translateX(0%);
    }

    &--nav_close {
      transform: translateX(150%);
    }

    &--navlink {
      display: block;
      width: 100%;
      padding: 1rem;
      text-align: right;

      &::after {
        content: '•';
        position: relative;
        bottom: inherit;
        margin: inherit;
        font-size: 18px;
        color: $font-color-blue;
        font-weight: bold;
      }

      &:hover {
        background-color: $theme-color-blue;
      }
    }

    &--quote {
      position: absolute;
      display: block;
      width: 50px;
      height: 50px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin-right: 1.8rem;
      color: $font-color-light;
      z-index: 99;
      cursor: pointer;

      &::after {
        content: '=';
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 68px;
        transform-origin: center;
      }
    }

    &--close_quote {

      &::after {
        content: '=';
        animation: .5s open-quote;
      }
    }

    &--open_quote {

      &::after {
        content: '×';
        animation: .5s close-quote;
      }
    }
  }
}

@media (max-width: 400px) {
  .header_app {
    
    &--nav {
      width: 52vw;
    }
  }
}

@media (min-height: 720px) {
  .header_app {
    height: $height-header-large-screen;
  }
}

@keyframes open-quote {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes close-quote {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}
</style>
