<template>

	<section class="banner_component">

		<div
			class="banner_component--panel"
			:style="{
				backgroundImage: `url(${bannerItems[0].background})`
			}"
		>
			<div
				class="banner_component--panel--bg"
				:style="{
					backgroundImage: `url(${bannerItems[0].image})`
				}"
			></div>
		</div>

		<!-- <div
			class="banner_component--panel"
			:class="{
				'banner_component--switch_panel': switch_panel
			}"
			:style="{
				backgroundImage: `url(${bannerCurrent.image})`
			}"
		>
			<div
				class="banner_component--info"
				:style="bannerCurrent.style"
			>
				<span>
					{{ bannerCurrent.text }}
				</span>
			</div>
		</div> -->

		<!-- <div
			class="banner_component--panel"
			:class="{
				'banner_component--switch_panel': switch_panel
			}"
			:style="{
				backgroundImage: `url(${bannerNext.image})`
			}"
		>
			<div
				class="banner_component--info"
				:style="bannerNext.style"
			>
				<span>
					{{ bannerNext.text }}
				</span>
			</div>
		</div> -->

		<!-- <div class="banner_component--quote">
			<div
				v-for="(item, id) in bannerData"
				:key="id"
				class="banner_component--quote_item"
				:class="[id === bannerCurrent.id ? 'banner_component--quote_active' : '']"
			>
			</div>
		</div> -->

	</section>

</template>

<script>
export default {
	name: 'Banner',
	props: {
		bannerData: Array
	},
	data () {
		return {
			bannerItems: [],
			bannerCurrent: {},
			bannerNext: {},
			switch_panel: false
		}
	},
	created () {
		if (this.sctructureBannerElements()) {
			setInterval(
				() => {
					this.switchBannerPanel()
				},
				5000
			);
		}

		return;
	},
	methods: {
		sctructureBannerElements () {
			this.bannerItems = this.bannerData.sort(
				(a, b) => {
					return a.id - b.id;
				}
			);

			const BANNER_ITEMS_LENGTH = this.bannerItems.length;

			if (BANNER_ITEMS_LENGTH > 0) {
				this.bannerCurrent = this.bannerItems[0];
			}

			if (BANNER_ITEMS_LENGTH > 1) {
				this.bannerNext = this.bannerItems[1];
			}

			return true;
		},
		switchBannerPanel () {
			this.switch_panel = true;

			setTimeout(
				() => {
					this.bannerCurrent = this.bannerNext;
					this.switch_panel = false;

					this.nextBannerPanel();
				},
				500
			);

			return;
		},
		nextBannerPanel () {
			this.bannerItems.some(
				(item, id, bannerItems) => {
					if (item === this.bannerNext && id === (bannerItems.length - 1)) {
						this.bannerNext = bannerItems[0];

						return true;
					}
					else if (item === this.bannerNext) {
						this.bannerNext = bannerItems[(id+1)];

						return true;
					}

					return false;
				}
			);

			return;
		}
	}
}
</script>

<style lang="scss">
@import './../assets/variables';

.banner_component {
	position: relative;	
	width: 200%;
	height: 100vh - $height-header-normal;
	margin-top: $height-header-normal;
	background: $theme-color-light;
	display: flex;

	&--panel {
		position: relative;
		top: 0;
		left: 0;
		width: 50%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		display: flex;
		align-items: center;

		&--bg {
			width: 80vw;
			height: 80%;
			background-repeat: no-repeat;
			-webkit-background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
			background-position: center;
		}
	}

	&--switch_panel {
		left: -50%;
		transition: left .5s $animation-cubic-bezier;
	}

	&--info {
		position: absolute;
		max-width: 550px;
		padding: 4rem 2rem;
		font-size: 36px;
		background-color: rgba(255, 255, 255, .75);
	}

	&--quote {
		position: absolute;
		width: 50%;
		height: 50px;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 90;
	}

	&--quote_item {
		width: 10px;
		height: 10px;
		margin: .5rem;
		border-radius: 50%;
		background: $font-color-light;
	}

	&--quote_active {
		background: $font-color-blue;
	}
}

@media (min-height: 720px) {
	.banner_component {
		height: 50vh;
		margin-top: $height-header-large-screen;
	}
}

@media (max-width: 720px) {
	.banner_component {
		&--panel--bg {
			width: 90vw;
			height: 75%;
			background-size: 100%;
		}
	}
}

// @media (max-width: 1080px) {
// 	.banner_component {
// 		&--panel {
// 			width: 50%;
// 			background-position: inherit;
// 			background-size: 100%;
// 		}
// 	}
// }

// @media (max-width: 720px) {
// 	.banner_component {
// 		height: 50vh;
// 	}
// }

@media (max-width: 640px) {
	.banner_component {
		height: 75vh - $height-header-normal;

		&--panel {
			&--bg {
				width: 100%;
				height: 50%;
				background-size: 100%;
			}
		}

		&--info {
			max-width: 75%;
			font-size: 26px;
			padding: 2.5rem 2rem;
		}
	}
}

@media (max-width: 500px) {
	.banner_component {
		height: 50vh - $height-header-normal;

		&--panel {
			&--bg {
				width: 100%;
				height: 60%;
				background-size: 100%;
			}
		}
	}
}
</style>
