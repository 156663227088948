var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header_app"},[_c('a',{attrs:{"href":_vm.href}},[_c('img',{staticClass:"header_app--img",attrs:{"src":require("./../assets/images/logo.png"),"alt":"Cope Logo"}})]),_c('nav',{staticClass:"header_app--nav",class:{
        'header_app--nav_close': !_vm.openNav,
        'header_app--nav_open': _vm.openNav
      }},[_c('ul',[_c('li',{on:{"click":function($event){return _vm.toggleQuote()}}},[_c('a',{staticClass:"header_app--navlink header_app--target",attrs:{"href":"https://planos.somoscope.com.br/","target":"_blank"}},[_vm._v(" Planos ")])]),_c('li',{on:{"click":function($event){return _vm.toggleQuote()}}},[_c('a',{staticClass:"header_app--navlink",attrs:{"href":"#about"}},[_vm._v(" Quem Somos ")])]),_c('li',{on:{"click":function($event){return _vm.toggleQuote()}}},[_c('a',{staticClass:"header_app--navlink",attrs:{"href":"#team"}},[_vm._v(" Equipe ")])]),_c('li',{on:{"click":function($event){return _vm.toggleQuote()}}},[_c('a',{staticClass:"header_app--navlink",attrs:{"href":"#copedigital"}},[_vm._v(" Cope Digital ")])]),_c('li',{on:{"click":function($event){return _vm.toggleQuote()}}},[_c('a',{staticClass:"header_app--navlink",attrs:{"href":"#contact"}},[_vm._v(" Contato ")])])])]),_c('div',{staticClass:"header_app--quote",class:{
        'header_app--close_quote': !_vm.openNav,
        'header_app--open_quote': _vm.openNav
      },on:{"click":function($event){return _vm.toggleQuote()}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }