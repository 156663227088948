<template>
  
  <section
		id="about"
		class="about_content"
		:style="{
			backgroundImage: `url(${aboutData.background})`
		}"
	>

		<div
			class="about_content--item"
			v-for="(item, id) in aboutItems"
			:key="id"
		>

			<img
				class="about_content--item_img"
				v-if="item.image.length > 0"
				:src="require(`@/assets/${item.image}`)"
				:alt="item.image"
			>

			<h2
				class="about_content--item_title"
				v-else-if="item.title"
			>
				{{ item.title }}
			</h2>

			<p
				class="about_content--item_text"
				v-else
			>
				{{ item.text }}
			</p>

		</div>

	</section>

</template>

<script>
export default {
	name: 'About',
	props: {
		aboutData: Object
	},
	data () {
		return {
			aboutItems: []
		}
	},
	created () {
		this.sctructureAboutElements();
	},
	methods: {
		sctructureAboutElements () {
			return this.aboutItems = this.aboutData.data.sort(
				(a, b) => {
					return a.id - b.id;
				}
			);
		}
	}
}
</script>

<style lang="scss">
@import './../assets/variables';
@import url('https://fonts.googleapis.com/css2?family=Pathway+Gothic+One&display=swap');

.about_content {
	display: flex;
	align-items: center;
	color: $font-color-light;
	flex-direction: column;
	width: 100%;
	padding-top: $height-header-normal;
	padding-bottom: 5rem;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

	&--item {
		width: 60%;
		margin: 1.5rem 0;
	}

	&--item_img {
		display: block;
		width: 75%;
		margin: 0 auto;
	}

	&--item_title {
		font-weight: normal;
		font-family: 'Pathway Gothic One', sans-serif;
		font-size: 2rem;
	}

	&--item_text {
		font-size: 2.5rem;
		font-family: source-han-sans-korean, sans-serif;
		font-weight: 700;
		font-style: normal;
	}
}

@media (max-width: 900px) {
	.about_content {

		&--item {
			width: 80%;
		}
	}
}
</style>
