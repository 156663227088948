var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"service_content",style:({
			backgroundImage: `url(${_vm.serviceData.background})`
		}),attrs:{"id":"services"}},[_c('div',{staticClass:"service_content--panel--header"},[_c('h2',{staticClass:"service_content--title"},[_vm._v(" "+_vm._s(_vm.serviceData.title)+" ")])]),_c('article',{staticClass:"service_content--panel"},_vm._l((_vm.serviceItems),function(item,id){return _c('div',{key:id,staticClass:"service_content--wrapper",attrs:{"id":item.anchor}},[_c('div',{staticClass:"service_content--wrapper--responsive"},[_c('h3',{staticClass:"service_content--wrapper--title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_vm._l((item.data),function(p,i){return _c('p',{key:i,staticClass:"service_content--wrapper--text",style:(p.style)},[_vm._v(" "+_vm._s(p.text)+" ")])})],2),_c('div',{staticClass:"service_content--wrapper--responsive"},[_c('div',{staticClass:"service_content--wrapper--image",style:({
							backgroundImage: `url(${item.image})`,
							backgroundAttachment: `${_vm.backgroundAttachment}`,
							backgroundSize: `${_vm.backgroundSize}`
						})})])])}),0),_c('article',{staticClass:"service_content--ps"},_vm._l((_vm.serviceData.ps),function(psItem,index){return _c('p',{key:index,staticClass:"service_content--ps--text",style:(psItem.style)},[_vm._v(" "+_vm._s(psItem.text)+" ")])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }