<template>
  <div id="app">

    <h1 style="display: none;">Cope Soluções Contábeis e Financeiras</h1>

    <HeaderApp/>

    <Banner
      :bannerData="siteData.banner"
    />

    <About
      :aboutData="siteData.about"
    />

    <Team
      :teamData="siteData.team"
    />

    <Service
      :serviceData="siteData.service"
    />

    <CopeDigital
      :copeDigitalData="siteData.cope_digital"
      :vemSerCopeData="siteData.vem_ser_cope"
      :newsletterData="siteData.newsletter"
    />

    <Contact
      :contactData="siteData.contact"
    />
    
  </div>
</template>

<script>
import SiteData from '@/data/sitedata.json';
import HeaderApp from '@/components/HeaderApp';
import Banner from '@/components/Banner';
import About from '@/components/About';
import Team from '@/components/Team';
import Service from '@/components/Service';
import CopeDigital from '@/components/CopeDigital';
import Contact from '@/components/Contact';

export default {
  name: 'App',
  components: {
    HeaderApp,
    Banner,
    About,
    Team,
    Service,
    CopeDigital,
    Contact
  },
  data () {
    return {
      siteData: SiteData
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: "Champagne & Limousines";
  src:
    url("./assets/fonts/Champagne & Limousines.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}

html,
body {
	overflow-x: hidden;
  font-family: source-han-sans-korean, sans-serif;
}

a {
  text-decoration: none !important;
}
</style>
