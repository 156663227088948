<template>
  
  <section
		id="services"
		class="service_content"
		:style="{
			backgroundImage: `url(${serviceData.background})`
		}"
	>

		<div class="service_content--panel--header">
			<h2 class="service_content--title">
				{{ serviceData.title }}
			</h2>
			<!-- <p class="service_content--subtitle">
				{{ serviceData.text }}
			</p> -->
		</div>

		<!-- <article class="service_content--panel">
			<p
				class="service_content--text"
				v-for="(item, id) in serviceItems"
				:key="id"
				:style="item.style"
			>
				{{ item.text }}
			</p>
		</article> -->
		<article class="service_content--panel">
			<div
				class="service_content--wrapper"
				v-for="(item, id) in serviceItems"
				:key="id"
				:id="item.anchor"
			>
				<div class="service_content--wrapper--responsive">
					<h3 class="service_content--wrapper--title">
						{{ item.title }}
					</h3>

					<p
						class="service_content--wrapper--text"
						v-for="(p, i) in item.data"
						:key="i"
						:style="p.style"
					>
						{{ p.text }}
					</p>
				</div>
				
				<div class="service_content--wrapper--responsive">
					<div
						class="service_content--wrapper--image"
						:style="{
							backgroundImage: `url(${item.image})`,
							backgroundAttachment: `${backgroundAttachment}`,
							backgroundSize: `${backgroundSize}`
						}"
					></div>
					
					<!-- <img
						class="service_content--wrapper--image"
						:src="item.image"
						:alt="item.title"
					> -->
				</div>

				<!-- <div
					class="service_content--wrapper--item"
					v-for="(wrapper_item, id) in item.data"
					:key="id"
				>
					<p
						:style="wrapper_item.style"
					>
						{{ wrapper_item.text }}
					</p>

					<p
						v-for="(service, id) in wrapper_item.data"
						:key="id"
						:style="service.style"
					>
						{{ service.text }}
					</p>
				</div> -->
			</div>
		</article>

		<article class="service_content--ps">
			<p
				class="service_content--ps--text"
				v-for="(psItem, index) in serviceData.ps"
				:key="index"
				:style="psItem.style"
			>
				{{ psItem.text }}
			</p>
		</article>

	</section>

</template>

<script>
export default {
	name: 'Service',
	props: {
		serviceData: Object
	},
	data () {
		return {
			serviceItems: [],
			backgroundAttachment: '',
			backgroundSize: 'cover'
		}
	},
	created () {
		this.sctructureServiceElements();
		this.isMobile();
	},
	methods: {
		isMobile () {
			let userAgent = navigator.userAgent || navigator.vendor || window.opera;

			if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
				this.backgroundAttachment = 'initial';
			}
		},
		sctructureServiceElements () {
			return this.serviceItems = this.serviceData.data.sort(
				(a, b) => {
					return a.id - b.id;
				}
			);
		}
	}
}
</script>

<style lang="scss">
@import './../assets/variables';
@import url('https://fonts.googleapis.com/css2?family=Pathway+Gothic+One&display=swap');

.service_content {
	width: 100%;
	padding-top: $height-header-normal;
	padding-bottom: 5rem;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-attachment: fixed;
	color: $font-color-dark;

	&--panel {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	
		&--header {
			display: flex;
		}
	}

	&--title {
		width: 100%;
		padding: 1.5rem 2.5rem;
		font-size: 36px;
		text-transform: uppercase;
		letter-spacing: 5px;
		text-align: left;
		background: $theme-color-blue;
		color: $theme-color-light;
	}

	&--subtitle {
		display: flex;
		align-items: center;
		width: 60%;
		padding: 0 5rem;
		font-size: 20px;
		text-indent: 2rem;
		text-align: justify;
	}

	&--text {
		padding: .3rem 3rem;
		font-size: 20px;
	}

	&--wrapper {
		width: 100%;
		margin: 4rem;
		margin-bottom: 0;
		padding-bottom: 4rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 2px solid $theme-color-blue;

		&:target {
			padding-top: 6rem;
		}

		&:last-child {
			border-bottom: none;
		}

		&--title {
			text-transform: uppercase;
			font-family: 'Pathway Gothic One', sans-serif;
			text-align: left;
			font-size: 2.5em;
			padding-bottom: 1rem;
		}

		&--item {
			font-size: 20px;
		}

		&--text {
			font-family: source-han-sans-korean, sans-serif;
			font-size: 1.1em;
			padding: 1rem 0;
		}

		&--image {
			height: 300px;
			width: 300px;
			margin-left: 2rem;
			background-repeat: no-repeat;
			-webkit-background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
		}
	}

	&--ps {
		margin-top: 2rem;
		padding: 6rem 3rem;
		font-size: 1.5em;
	}
}

@media (max-width: 1080px) {
	.service_content {
		
		&--panel {
			&--header {
				display: block;
			}
		}

		&--title {
			padding: 1.5rem 1.5rem;
		}

		&--subtitle {
			width: 100%;
			padding: 2rem 5rem;
		}
	}
}

@media (max-width: 900px) {
	.service_content {
		flex-direction: column;

		&--panel {
			margin: .5rem 0;
			width: 100%;
		}

		&--text {
			padding: .3rem 2rem;
		}

		&--wrapper {
			margin-top: 2rem;
			display: flex;
			align-items: flex-start;
			flex-direction: column;

			&--responsive {
				width: 100%;
			}

			&--image {
				height: 250px;
				width: 100%;
				margin-top: 2rem;
				margin-left: 0;
				background-attachment: fixed;
			}
		}
	}
}

@media (max-width: 600px) {
	.service_content {
		
		&--subtitle {
			padding: 1rem 2rem;
		}

		&--wrapper {
			margin: 2rem 1rem;

			p {
				padding-left: 1rem !important;
				padding-right: 1rem !important;
			}
		}
	}
}
</style>
